import * as Types from '../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductHierarchyFragmentFragment = { path: string, depth: number, doesNodeRepresentGroup: boolean, member?: { id: string, name: string, size?: string | null, unitsPerCase?: number | null, productCodes: Array<{ id: string, code: string }> } | null, group?: { id: string, name: string, isRoot: boolean } | null };

export type GetAllProductGroupsInHierarchyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['UUID'];
  options?: Types.InputMaybe<Types.ProductHierarchyNextOptions>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllProductGroupsInHierarchyQuery = { getHierarchy: { steplen: number, totalDescendants: number, currentNode?: { path: string, depth: number, doesNodeRepresentGroup: boolean, member?: { id: string, name: string, size?: string | null, unitsPerCase?: number | null, productCodes: Array<{ id: string, code: string }> } | null, group?: { id: string, name: string, isRoot: boolean } | null } | null, descendants: Array<{ path: string, depth: number, doesNodeRepresentGroup: boolean, member?: { id: string, name: string, size?: string | null, unitsPerCase?: number | null, productCodes: Array<{ id: string, code: string }> } | null, group?: { id: string, name: string, isRoot: boolean } | null }> } };

export const ProductHierarchyFragmentFragmentDoc = gql`
    fragment ProductHierarchyFragment on ProductHierarchyNode {
  path
  depth
  doesNodeRepresentGroup: doesNodeRepresentProductGroup
  member: product {
    id
    name
    size
    unitsPerCase
    productCodes {
      id
      code
    }
  }
  group: productGroup {
    id
    name
    isRoot
  }
}
    `;
export const GetAllProductGroupsInHierarchyDocument = gql`
    query getAllProductGroupsInHierarchy($companyId: UUID!, $options: ProductHierarchyNextOptions, $limit: Int, $offset: Int) {
  getHierarchy: getAllProductGroupsInHierarchy(
    companyId: $companyId
    options: $options
    limit: $limit
    offset: $offset
  ) {
    steplen
    totalDescendants
    currentNode {
      ...ProductHierarchyFragment
    }
    descendants {
      ...ProductHierarchyFragment
    }
  }
}
    ${ProductHierarchyFragmentFragmentDoc}`;

export function useGetAllProductGroupsInHierarchyQuery(options: Omit<Urql.UseQueryArgs<GetAllProductGroupsInHierarchyQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllProductGroupsInHierarchyQuery>({ query: GetAllProductGroupsInHierarchyDocument, ...options });
};